import { useEffect, useState } from "react";

import Project from "./Project";
import { IProject, ISite } from "../../api/Interfaces";
import {
  Column,
  Img,
  List,
  ListTitle,
  Row,
  StyledDiv,
  MapText,
} from "./styled";
import "../../assets/css/Loader.css";
import ToArrow from "../../assets/svg/ToArrow.svg";
import CameraIcon from "../../assets/svg/CameraIcon.svg";
import CameraIconDark from "../../assets/svg/CameraIconDark.svg";
import Icon360 from "../../assets/svg/Icon360.svg";
import Icon360Dark from "../../assets/svg/Icon360Dark.svg";
import Light360 from "../../assets/svg/Light360.svg";
import Dark360 from "../../assets/svg/Dark360.svg";

import "./Projects.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Timeout } from "../public/Login";
import { SensorType } from "../../api/Enums";

// https://onlinepngtools.com/convert-png-to-base64
import MapImgNassjo from "../../assets/svg/mapImg1.svg";
import MapImgKarlskrona from "../../assets/svg/mapImg2.svg";
import MapImgAngeBrackeSundsvall from "../../assets/svg/mapImg3.svg";
import MapImgAngeKilafors from "../../assets/svg/mapImg4.svg";
import MapImgBorasVarberg from "../../assets/svg/mapImg5.svg"
import MapImgOlskrokenVarberg from "../../assets/svg/mapImg6.svg"
import MapImgAlvsbynPitea from "../../assets/svg/mapImg7.svg"
import MapImgBastutraskSkelleftehamn from "../../assets/svg/mapImg8.svg"
import MapImgBorasAlmedal from "../../assets/svg/mapImg9.svg"
import MapImgERTMSLund from "../../assets/svg/mapImg10.svg"

const API_URL: string | undefined = process.env.REACT_APP_API_URL;

function dispProjectDetails(
  fromPlace: string,
  toPlace: string,
  scanDate: string,
  trackSections: string,
  mapDisp: string,
  sensorDetails: Array<string>
) {
  return (
    <Column xs={7} css="">
      <StyledDiv
        css="background-image:
                            linear-gradient(180deg, rgba(50, 54, 68, 1) 55%,
                                            rgba(50, 54, 68, 0.9) 60%,
                                            rgba(50, 54, 68, 0.8) 90%,
                                            rgba(50, 54, 68, 0.1) 100%);
                            height: auto;
                            margin: 138px 10%;
                            padding: 25px;
                            border-radius: 15px;
                            "
      >
        <Row
          css="
                        margin:0px;
                        padding: 10px;
                        display: flex;
                        justify-content: space-between;
                "
        >
          <Column xs={3} md={3} lg={3}>
            <MapText
              css="
                            font-weight: 800;
                            font-size: 15px;
                            color: #9AA5B5;
                        "
            >
              From
            </MapText>
            <MapText
              css="
                        font-weight: 800;
                        font-size: 18px;
                        "
            >
              {fromPlace}
            </MapText>
          </Column>
          <Column xs={2} md={2} lg={2} css="margin-top: 22px;">
            <Img src={ToArrow} />
          </Column>
          <Column xs={7} md={7} lg={7}>
            <MapText
              css="
                            font-weight: 800;
                            font-size: 15px;
                            color: #9AA5B5;
                        "
            >
              To
            </MapText>
            <MapText
              css="
                        font-weight: 800;
                        font-size: 18px;
                        "
            >
              {toPlace}
            </MapText>
          </Column>
        </Row>
        <Row
          css="margin: 10px; padding-left: 10px;
                            background-color: rgba(39, 42, 55, 1);
                            min-width: 445px;
                            border-radius: 15px;"
        >
          <Column xs={3} md={3} lg={3} css="padding: 10px;">
            <MapText
              css="
                                font-weight: 800;
                                font-size: 10px;
                                color: #9AA5B5;
                            "
            >
              Date
            </MapText>
            <MapText
              css="
                                font-weight: 800;
                                font-size: 12px;
                            "
            >
              {scanDate}
            </MapText>
          </Column>
          <Column xs={4} md={4} lg={4} css="padding: 10px;">
            <MapText
              css="
                                font-weight: 800;
                                font-size: 10px;
                                color: #9AA5B5;
                            "
            >
              Track Sections
            </MapText>
            <MapText
              css="
                                font-weight: 800;
                                font-size: 12px;
                            "
            >
              {trackSections}
            </MapText>
          </Column>
          <Column
            xs={5}
            md={5}
            lg={5}
            css="padding: 0px;
                                        display: flex;
                                        justify-content: space-between;"
          >
            <Column xs={4} md={4} lg={4} css="padding: 10px;">
              <MapText
                css="
                                    font-weight: 800;
                                    font-size: 10px;
                                    color: #9AA5B5;
                                "
              >
                Camera
              </MapText>
              <Img css="margin-left: 8px;" src={sensorDetails[0]} />
            </Column>
            <Column xs={4} md={4} lg={4} css="padding: 10px;">
              <MapText
                css="
                                    font-weight: 800;
                                    font-size: 10px;
                                    color: #9AA5B5;
                                "
              >
                Lidar
              </MapText>
              <Img css="margin-left: 3px;" src={sensorDetails[1]} />
            </Column>
            <Column xs={4} md={4} lg={4} css="padding: 10px;">
              <MapText
                css="
                                    font-weight: 800;
                                    font-size: 10px;
                                    color: #9AA5B5;
                                "
              >
                360°
              </MapText>
              <Img
                css="margin-left: 0px;"
                width="20px"
                src={sensorDetails[2]}
              />
            </Column>
          </Column>
        </Row>
        <Row
          css="margin: 0px 10px 0 10px;
                            background-color: rgba(39, 42, 55, 1);
                            min-width: 435px;
                            width: 100%-10px;
                            border-radius: 15px;"
        >
          <Img
            css="
                            padding: 10px;
                            position: relative;
                            border-radius: 20px;
                            "
            src={mapDisp}
            width="100%"
          />
        </Row>
      </StyledDiv>
    </Column>
  );
}

function buildSensorScanStatus(viewProject: IProject) {
  const cameraIcon = viewProject.scan_sessions.map((scan_session) => {
    return scan_session.sensor_scans.some(sensor_scan => (sensor_scan.type === SensorType.camera && sensor_scan.allowed))
  }).some(x => x) ? CameraIcon : CameraIconDark;
  const lidarIcon = viewProject.scan_sessions.map((scan_session) => {
    return scan_session.sensor_scans.some(sensor_scan => (sensor_scan.type === SensorType.lidar && sensor_scan.allowed))
  }).some(x => x) ? Icon360 : Icon360Dark;
  const panoramaIcon = viewProject.scan_sessions.map((scan_session) => {
    return scan_session.sensor_scans.some(sensor_scan => (sensor_scan.type === SensorType.panoramic_camera && sensor_scan.allowed))
  }).some(x => x) ? Light360 : Dark360;
  const viewSensors: Array<string> = [cameraIcon, lidarIcon, panoramaIcon];

  return viewSensors;
}

function dispStaticProject(viewProject: IProject) {
  const dispSensorIcons: Array<string> = buildSensorScanStatus(viewProject);
  const pid: number = viewProject.id;
  const sites: Array<ISite> = viewProject.scan_sessions[0].sites
  const fromSite: string = sites[0].location;
  const toSite: string = sites[sites.length - 1].location;
  const date: Date = new Date(viewProject.scan_sessions[0].date);
  let img: string = "";
  let tracklinks: string = "";
  if ([1, 2, 3, 4].includes(pid)) {
    img = MapImgNassjo;
    tracklinks = '831, 817'
  }
  else if ([5, 8, 9].includes(pid)) {
    img = MapImgKarlskrona;
    tracklinks = '943, 823'
  }
  else if ([6, 11, 12].includes(pid)) {
    img = MapImgAngeBrackeSundsvall;
    tracklinks = '210, 212, 213, 224'
  }
  else if ([7, 10, 26].includes(pid)) {
    img = MapImgAngeKilafors;
    tracklinks = '210, 212, 213, 215, 216, 217, 218, 251'
  }
  else if ([14, 25, 27, 36].includes(pid)) {
    img = MapImgBorasVarberg;
    tracklinks = '627, 655, 656'
  }
  else if ([16, 17, 18, 19, 20].includes(pid)) {
    img = MapImgOlskrokenVarberg;
    tracklinks = '601, 626, 627'
  }
  else if ([21, 22].includes(pid)) {
    img = MapImgAlvsbynPitea;
    tracklinks = '004, 124, 141'
  }
  else if ([23, 24].includes(pid)) {
    img = MapImgBastutraskSkelleftehamn;
    tracklinks = '124, 143'
  }
  else if ([34, 35].includes(pid)) {
    img = MapImgBorasAlmedal;
    tracklinks = '601, 626, 641, 655'
  }
  else if ([31, 32].includes(pid)) {
    img = MapImgERTMSLund;
    tracklinks = '901, 902, 938, 940, 912, 925, 927'
  }
  else
    return undefined;

  return dispProjectDetails(fromSite, toSite, date.toDateString(), tracklinks, img, dispSensorIcons);
}

export default function Projects() {
  const [activeProjectId, setActiveProjectId] = useState<number>(0);
  const [projectList, setProjectList] = useState<Array<IProject>>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const response = await fetch(`${API_URL}/projects`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        signal: Timeout(7).signal,
      });
      const data = await response.json();
      setProjectList(data);
      if (response.ok) {
        setLoaded(true);
      }
    })().catch(console.error);
  }, []);

  return loaded ? (
    <StyledDiv
      css="display: flex;
                        justify-content: space-between;
                        margin:auto; margin-left: 0px;
                        "
    >
      <Column xs={5} css="">
        <ListTitle css="margin-top: 30px; text-align: left; margin-left: 15%">
          Projects
        </ListTitle>
        <StyledDiv
          css="background-image:
                                linear-gradient(180deg, rgba(50, 54, 68, 1) 55%,
                                                rgba(50, 54, 68, 0.9) 60%,
                                                rgba(50, 54, 68, 0.8) 90%,
                                                rgba(50, 54, 68, 0.1) 100%);
                                height: auto;
                                margin: 38px 0 0 15%;
                                padding: 15px;
                                border-radius: 15px;
                                "
        >
          <List css="">
            {projectList.map((project: IProject, index: number) => (
              <div
                key={project.id}
                onMouseEnter={() => setActiveProjectId(index)}
              >
                <Project project={project} initialOpen={!index} />
              </div>
            ))}
          </List>
        </StyledDiv>
      </Column>

      {dispStaticProject(projectList[activeProjectId])}
    </StyledDiv>
  ) : (
    <div className="loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
